import { Locale, Segment } from "src/sanity/types"

/**
 * Generate an appstore appsflyer onelink for the client's specific platform.
 * @param { ids } An object of all the ad and tracking ids of the user (Google Analytics Client ID, Facebook Click ID, Google Ads Click ID, Adtraction Click ID etc)
 * @param { gclid } The google clickid to associate subsequent install events to.
 * @param { msclkid } The Microsoft clickid to associate subsequent install events to.
 * @param { fbclid } The Facebook clickid to associate subsequent install events to.
 * @param { adtractionId } The Adtraction ID partner clickid to associate subsequent install events to.
 * @param { source } The source to associate subsequent install events to.
 * @param { fallbackUrl } The fallback url for when the platform is desktop.
 */
export function createAppLinkUrl({
    ids = {},
    source = "",
    campaign = "",
    deviceType = "Mobile",
    deepLinkUrl = "lunarway://",
    fallbackUrl,
    onelinkUrl = "https://lunarway.onelink.me/1703574611",
    testVariation = "",
}: {
    ids?: AppsFlyerIds
    source?: string
    campaign?: string
    deviceType?: string
    deepLinkUrl?: string
    fallbackUrl?: string
    onelinkUrl?: string
    testVariation?: string
}): string {
    ids = Object.fromEntries(Object.entries(ids).filter(([, v]) => v != null)) // Remove empty values

    // Determine source of visit to website
    const lcSource = source?.toLowerCase()
    let websiteVisitSource = source || "Organic"
    if (ids.gclid || lcSource?.includes("google")) websiteVisitSource = "Google Ads"
    if (ids.msclkid) websiteVisitSource = "Microsoft Ads"
    if (ids.fbclid || lcSource?.includes("facebook") || lcSource?.includes("meta")) websiteVisitSource = "Facebook Ads"
    if (lcSource?.includes("linkedin")) websiteVisitSource = "LinkedIn Ads"
    if (ids.adtractionId) websiteVisitSource = "Adtraction partner"

    // Determine campaign name
    let campaignName = campaign || source
    if (websiteVisitSource === "Organic" && typeof document !== "undefined") {
        if (document.referrer.includes("google")) campaignName = "Google Organic"
        if (document.referrer.includes("bing")) campaignName = "Bing Organic"
        if (ids.fbclid || document.referrer.includes("facebook")) campaignName = "Facebook Organic"
    }

    // We mark the AppsFlyer OneLink source ("pid") as "website"
    // And then the source/acquisition of the website visit (Google, Facebook etc.) goes into the channel field
    const queryString = new URLSearchParams({
        pid: "website",
        ...(campaignName ? { c: campaignName } : {}),
        af_channel: websiteVisitSource,
        af_adset: deviceType, // "Mobile" by default, or overwrite to "Desktop" if coming from SMS
        af_dp: deepLinkUrl,
        ...(fallbackUrl ? { af_web_dp: fallbackUrl } : {}),
        ...(testVariation ? { af_ad: testVariation } : {}),
        ...(ids.gclid ? { gclidParam: "af_sub1", af_sub1: ids.gclid } : {}),
        ...(ids.msclkid ? { af_sub2: ids.msclkid } : {}),
        ...(Object.values(ids).length ? { af_sub5: encodeURIComponent(new URLSearchParams(ids).toString()) } : {}), // Send client id and timestamp to AppsFlyer so we can backdate conversion events (install, signup)
    })

    // Allow defined onelinkURL to override the parameters
    if (onelinkUrl?.includes("?")) {
        const onelinkUrlSearchParams = new URLSearchParams(onelinkUrl.split("?")[1])
        onelinkUrlSearchParams.forEach((value, key) => {
            queryString.set(key, value)
        })
    }

    return `${onelinkUrl.split("?")[0]}?${queryString.toString()}`
}

type DownloadLinkParamters = {
    ids: Record<string, string>
    segment: Segment
    lang: Locale
    linkSource: string
    source?: string
    campaign?: string
    onelinkPath?: string
}

export function createParametersForDownloadLink({ ids, segment, lang = "dk", linkSource, source, campaign, onelinkPath }: DownloadLinkParamters) {
    if (linkSource === "sms" && ids?.fbclid)
        delete ids.fbclid

    const shortNameIds = ids ? longIdNamesToShort(ids) : null
    const idsParam = shortNameIds ? encodeURIComponent(new URLSearchParams(shortNameIds).toString()) : null
    return {
        ...(idsParam ? { i: idsParam } : {}),
        // t: Math.floor(Date.now() / 1000).toString(),
        s: segment === "business" ? "b" : "c",
        m: ["dk", "en"].includes(lang) ? "dk" : lang,
        ls: linkSource,
        ...(source ? { ws: source } : {}),
        ...(campaign ? { wc: campaign } : {}),
        // Don't include long onelinkPath if it's the default one, and we can identify it via segment
        ...(onelinkPath && !(segment === "business" && onelinkPath.includes("scntyxvq")) ? { ol: onelinkPath } : {}),
    }
}

export function createDeferredDownloadLink(params: DownloadLinkParamters) {
    const urlEncodedParam = new URLSearchParams(createParametersForDownloadLink(params)).toString()
    return `https://lunar.app/dl?${urlEncodedParam}`
}

const idToShortMap = {
    gclid: "g",
    msclkid: "m",
    fbclid: "f",
    adtractionId: "a",
    gaClientId: "ga",
    gaSessionId: "s",
}

export type AppsFlyerIds = Partial<Record<keyof (typeof idToShortMap), string>> & { installLeadTimestamp?: string }

// Used to shorten the parameter send in SMS's to users, so it doesn't look quite as long
export function longIdNamesToShort(ids: AppsFlyerIds): Record<string, string> {
    return Object.entries(idToShortMap).reduce((acc, [idLongName, idShortName]) => {
        if (ids?.[idLongName])
            acc[idShortName] = ids[idLongName]
        if (idLongName === "gaClientId" && ids?.installLeadTimestamp)
            acc[idShortName] += `-${ids.installLeadTimestamp}`
        return acc
    }, {})
}
export function shortIdNamesToLong(ids: Partial<(typeof idToShortMap)>): AppsFlyerIds {
    const longIds: AppsFlyerIds = Object.entries(idToShortMap).reduce((acc, [idLongName, idShortName]) => {
        if (ids[idShortName])
            acc[idLongName] = ids[idShortName]
        return acc
    }, {})

    if (longIds.gaClientId && longIds.gaClientId.includes("-")) {
        const [clientId, installLeadTimestamp] = longIds.gaClientId.split("-")
        longIds.gaClientId = clientId
        longIds.installLeadTimestamp = installLeadTimestamp.padEnd(13, "9")
    }
    return longIds
}

// idParams: formatted as ga%3D1411297591.1699455185-1699455200887%26s%3D1699455184
export function unpackIdParam(idParams: string) {
    try {
        const decoded = decodeURIComponent(idParams)
        const searchParams = new URLSearchParams(decoded)
        const rawObject = Object.fromEntries(searchParams.entries())
        const isShortStyle = Object.values(idToShortMap).some(shortId => rawObject[shortId])
        const longIds = isShortStyle ? shortIdNamesToLong(rawObject) : rawObject

        if (longIds.gaClientId && longIds.gaClientId.includes("-")) {
            const [clientId, installLeadTimestamp] = longIds.gaClientId.split("-")
            longIds.gaClientId = clientId
            longIds.installLeadTimestamp = installLeadTimestamp.padEnd(13, "9")
        }

        return longIds
    } catch (e) {
        console.error("Failed to unpack ids", idParams, ". Error:", e)
    }
}
