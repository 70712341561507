import { useEffect, useState } from "react"

declare global {
    interface Window {
        CookieInformation: any
        ppms: any
    }
}

export function convertLangToCulture(lang: Sanity.Schema.Page["lang"]) {
    switch (lang) {
        case "dk":
            return "DA"
        case "se":
            return "SV"
        case "no":
            return "NB"
        case "en":
        default:
            return "EN"
    }
}

interface Consent {
    neccessary: boolean
    functional: boolean
    statistic: boolean
    marketing: boolean
    unclassified: boolean
}

export function getConsentFromCookieInformation(): Consent {
    if (typeof window === "undefined" || !window.CookieInformation) {
        return {
            neccessary: false,
            functional: false,
            statistic: false,
            marketing: false,
            unclassified: false,
        }
    }

    const CookieInformation = window.CookieInformation
    try {
        return {
            neccessary: CookieInformation?.getConsentGivenFor("cookie_cat_necessary") || false,
            functional: CookieInformation?.getConsentGivenFor("cookie_cat_functional") || false,
            statistic: CookieInformation?.getConsentGivenFor("cookie_cat_statistic") || false,
            marketing: CookieInformation?.getConsentGivenFor("cookie_cat_marketing") || false,
            unclassified: CookieInformation?.getConsentGivenFor("cookie_cat_unclassified") || false,
        }
    } catch (error) {
        return {
            neccessary: false,
            functional: false,
            statistic: false,
            marketing: false,
            unclassified: false,
        }
    }
}

export function useCookieconsent() {
    const [consent, setConsent] = useState<Consent>({
        neccessary: null,
        functional: null,
        statistic: null,
        marketing: null,
        unclassified: null,
    })

    useEffect(() => {
        function handleConsentUpdate() {
            const consentStatus = getConsentFromCookieInformation()
            setConsent(consentStatus)
        }
        handleConsentUpdate()

        window.addEventListener("CookieInformationConsentGiven", handleConsentUpdate, false)

        return () => {
            window.removeEventListener("CookieInformationConsentGiven", handleConsentUpdate)
        }
    }, [])

    return consent
}

export async function onConsentReady() {
    return new Promise<void>((resolve) => {
        if (typeof window.CookieInformation?.getConsentGivenFor("cookie_cat_marketing") !== "undefined")
            resolve()

        window.addEventListener("CookieInformationConsentGiven", function () {
            resolve()
        })
    })
}

