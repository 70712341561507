export type Language = "dk" | "en" | "se" | "no"
export const languageMap = {
    "dk": {
        name: "Danmark",
        countryCode: "+45",
        intlLocale: "da-DK",
    },
    "en": {
        name: "Denmark (English)",
        countryCode: "+45",
        intlLocale: "en-DK",
    },
    "se": {
        name: "Sverige",
        countryCode: "+46",
        intlLocale: "sv-SE",
    },
    "no": {
        name: "Norge",
        countryCode: "+47",
        intlLocale: "no-NO",
    },
} as const
